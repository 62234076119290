<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="gameApi.isError" :api="gameApi" />
        <ApiErrorDialog v-if="PlayerApi.isError" :api="PlayerApi" />
        <ApiErrorDialog
          v-if="SeriesSponsorApi.isError"
          :api="SeriesSponsorApi"
        />
        <ApiErrorDialog v-if="EventSponsorApi.isError" :api="EventSponsorApi" />
        <!-- EOC -->
        <v-container
          class="app-body d-flex flex-column justify-center align-center"
          v-if="selectYearScreen"
        >
          <div class="text-h4 text-center brown--text">
            {{ $t("string.select_year") }}
          </div>
          <div class="text-center">
            <v-select
              :items="lables"
              item-text="name"
              item-value="id"
              v-model="selectedYear"
              background-color="transparent"
              item-color="brown"
              flat
              dense
              hide-details
              solo
              class="ma-3 custom-select"
              color="brown"
            ></v-select>
            <v-btn @click="selectYear()" width="100">
              {{ $t("action.go") }}
            </v-btn>
          </div>
        </v-container>
        <v-container
          class="app-body d-flex flex-column justify-center align-center"
          v-else-if="sponsorScreen"
        >
          <div class="text-h4 text-center brown--text">
            {{ $t("string.sponsors") }}
          </div>
          <div
            class="d-flex flex-column flex-wrap align-center justify-center my-3"
            v-if="seriesSponsors.length > 0"
          >
            <!-- <div class="my-2">
                <v-divider :thickness="3" width="100%"></v-divider>
                Series Sponsors
                <v-divider :thickness="3" width="100%"></v-divider>
              </div> -->
            <div
              v-for="(sponsor, j) in seriesSponsors"
              :key="j"
              class="d-flex flex-column align-center mx-3 topic"
              style="max-width: 150px"
            >
              <v-img
                v-if="sponsor.Sponsor.logoUrl"
                :src="sponsor.Sponsor.logoUrl"
                max-width="80"
              >
              </v-img>
              <div
                class="text-center brown--text text--darken-2 mt-2"
                style="line-height: 20px"
              >
                {{ sponsor.Sponsor.name }}
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-wrap flex-column align-center justify-center my-3"
            v-if="eventSponsors.length > 0"
          >
            <v-divider :thickness="3" width="100%" class="mb-4"></v-divider>
            <!-- <div class="my-2">
                <v-divider :thickness="3" width="100%"></v-divider>
                Event Sponsors
                <v-divider :thickness="3" width="100%"></v-divider>
              </div> -->
            <div
              v-for="(sponsor, j) in eventSponsors"
              :key="j"
              class="d-flex flex-column align-center mx-3 topic"
              style="max-width: 150px"
            >
              <v-img
                v-if="sponsor.Sponsor.logoUrl"
                :src="sponsor.Sponsor.logoUrl"
                max-width="80"
              >
              </v-img>
              <div class="text-center brown--text text--darken-2">
                {{ sponsor.Sponsor.name }}
              </div>
            </div>
          </div>
          <v-progress-linear
            color="brown darken-1"
            indeterminate
            height="10"
            style="max-width: 200px"
            class="my-5"
          ></v-progress-linear>
        </v-container>
        <v-container class="app-body" v-else>
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.hamochi_cup") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <Loader :isLoading="api.isLoading" />
          <div class="text-h6 text-center mb-3" style="color: #ab7e56">
            {{ this.series.name }}
          </div>
          <Empty :condition="events.length < 1 && api.isLoading == false" />
          <div class="nav-panel" v-if="events">
            <div
              v-for="(nav, j) in events"
              :key="j"
              :class="nav.id == selected.id ? 'selected-nav' : 'nav'"
              @click="changeTab(nav)"
            >
              <div class="container-1x1">
                <div class="aspect-ratio-item">
                  <v-img
                    :src="require('@/assets/explore/menu/prize.png')"
                    :width="nav.id == selected.id ? 150 : 100"
                    class="justify-center align-center text-center"
                  >
                    <template v-slot:placeholder>
                      <div class="loader"></div>
                    </template>
                    <v-img
                      :src="require('@/assets/explore/menu/tick.png')"
                      width="20"
                      class="mx-auto"
                      v-if="
                        status(nav.timestampStart, nav.timestampEnd) == 'PAST'
                      "
                    >
                    </v-img>
                    <h3
                      v-else
                      style="
                        color: white;
                        font-family: 'Times New Roman', Times, serif;
                      "
                    >
                      {{ $_toRoman(j + 1) }}
                    </h3>
                    <div
                      style="
                        position: absolute;
                        bottom: 5%;
                        text-align: center;
                        width: 100%;
                        color: #fff;
                      "
                      class="text-uppercase"
                    >
                      {{
                        $moment(nav.timestampStart)
                          .locale($_getLocaleForMoment())
                          .format("MMM")
                      }}
                    </div>
                  </v-img>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected" class="game-card">
            <div class="d-flex justify-space-between px-2 align-center">
              <div class="d-flex align-center">
                <div class="text-h5 mt-3 game-title">
                  <!-- {{ $t("title.game") }} -->
                  {{ selected.name }}
                </div>
              </div>
              <div
                class="d-flex align-center ticket-card"
                v-if="
                  status(selected.timestampStart, selected.timestampEnd) ==
                  'ONGOING'
                "
              >
                <v-img
                  contain
                  width="30"
                  :src="require('@/assets/explore/menu/ticket.png')"
                ></v-img>
                <div class="text-h5 ps-2 game-title">{{ ticketCount }}</div>
              </div>
            </div>
            <div class="container-16x9 px-2">
              <div class="aspect-ratio-item">
                <v-img
                  v-if="selected.game"
                  contain
                  height="auto"
                  class="mx-2"
                  style="border: #8b5629 solid 5px; border-radius: 5px"
                  :src="selected.game.imageUrl"
                >
                  <template v-slot:placeholder>
                    <div class="loader"></div>
                  </template>
                </v-img>
                <v-img
                  v-else-if="selectedImg"
                  contain
                  height="auto"
                  class="mx-2"
                  style="border: #8b5629 solid 5px; border-radius: 5px"
                  :src="selectedImg"
                >
                  <template v-slot:placeholder>
                    <div class="loader"></div>
                  </template>
                </v-img>
              </div>
            </div>
            <div
              class="d-flex flex-column"
              v-if="
                status(selected.timestampStart, selected.timestampEnd) ==
                'ONGOING'
              "
            >
              <div class="text-h1 text-stroke-lg">
                {{ $moment(selected.timestampEnd).diff($moment(), "days") }}
              </div>
              <div class="text-h4 text-stroke-sm">
                {{ $t("string.days_left") }}
              </div>
              <div class="my-4">
                <v-btn
                  large
                  color="yellow text-h5"
                  @click="goToLevels(selected.code)"
                  id="StartNow"
                  >{{ $t("string.start_now") }}</v-btn
                >
              </div>
            </div>
            <div
              class="d-flex flex-column"
              v-else-if="
                status(selected.timestampStart, selected.timestampEnd) ==
                'UPCOMING'
              "
            >
              <div class="text-h6 mb-3 mt-3" style="color: #8b5629">
                {{ $t("view.PageParticipantLanding.countdown_start") }}
              </div>
              <div class="text-h1 text-stroke-md" style="line-height: 60px">
                {{ $moment(selected.timestampStart).diff($moment(), "days") }}
              </div>
              <div class="text-h4 text-stroke-sm">{{ $t("string.days") }}</div>
              <div class="my-4">
                <v-btn
                  x-large
                  color="yellow"
                  @click="goToInfo(selected.code)"
                  >{{ $t("string.read_rules") }}</v-btn
                >
              </div>
            </div>
            <div
              class="d-flex flex-column my-3"
              v-else-if="
                status(selected.timestampStart, selected.timestampEnd) ==
                'LONGUPCOMING'
              "
            >
              <div class="text-h5 text-stroke-sm">
                {{ $t("string.coming_on") }}
                {{
                  $moment(selected.timestampStart)
                    .locale($_getLocaleForMoment())
                    .format("MMM D, YYYY")
                }}
              </div>
            </div>
            <div class="d-flex flex-column my-3" v-else>
              <div class="text-h4 text-stroke-sm">It's Over</div>
              <div class="my-4">
                <v-btn small color="yellow" @click="goToRank(selected.code)">{{
                  $t("string.view_result")
                }}</v-btn>
              </div>
            </div>
            <div style="color: #8b5629">
              {{
                $moment(selected.timestampStart).format("DD/MM/YYYY HH:mm A")
              }}
              ~
              {{ $moment(selected.timestampEnd).format("DD/MM/YYYY HH:mm A") }}
            </div>
          </div>
        </v-container>
        <SkipTut />
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import delay from "delay";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    tutorial: (state) => state.tutorial.data,
  }),
  props: [
    //
  ],
  data: () => ({
    intraction: null,
    selectedYear: 1,
    seriesSponsors: [],
    eventSponsors: [],
    sponsorScreen: false,
    selectYearScreen: false,
    ticketCount: 0,
    selectedImg: null,
    api: {
      isLoading: true,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    PlayerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    SeriesSponsorApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    EventSponsorApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    selected: null,
    events: [],
    series: {},
    nextCode: null,
    nextRoute: null,
  }),
  created() {
    this.api.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/series";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;

      this.series = resp;

      if (!this.series || this.series == "") {
        return false;
      }

      this.events = resp.Event;
      if (resp.Event.length < 1) return;

      if (
        this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "ONGOING"
        )
      )
        this.selected = this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "ONGOING"
        );
      else if (
        this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "UPCOMING"
        )
      )
        this.selected = this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "UPCOMING"
        );
      else if (
        this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "UPCOMING"
        )
      )
        this.selected = this.$_.cloneDeep(this.events)
          .reverse()
          .find(
            (element) =>
              this.status(element.timestampStart, element.timestampEnd) ==
              "PAST"
          );
      else this.selected = this.events[0];

      if (
        this.status(this.selected.timestampStart, this.selected.timestampEnd) ==
        "ONGOING"
      ) {
        this.tickets(this.selected.code);
      }

      // #tutExploreEvents - continue intro js ecplore Event tutorial
      if (this.tutorial.Ongoing == "tutExploreEvents") {
        if (
          this.status(
            this.selected.timestampStart,
            this.selected.timestampEnd
          ) == "ONGOING"
        ) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreEvents";
          tempTut.Step = 3;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(() => {
            this.$intro().exit();
            this.$intro()
              .setOptions({
                exitOnOverlayClick: false,
                showButtons: false,
                showBullets: false,
                steps: [
                  {
                    element: document.querySelector("#StartNow"),
                    intro:
                      '<img src="' +
                      require("@/assets/tutorial/chat_1.png") +
                      '">' +
                      this.$t("tutorial.exploreEvents.dialog_3"),
                  },
                ],
              })
              .start();
            this.intraction = "pointer-events: all;";
            let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
            audio.volume = this.settings.audio.sfx * this.settings.audio.master;
            audio.play();
          }, 1000);
        } else {
          this.updateTutorial();
        }
      }
      this.getGameImage();
    };
  },
  mounted() {
    this.lables = [
      { id: 1, name: this.$t("string.year", { year: 1 }) },
      { id: 2, name: this.$t("string.year", { year: 2 }) },
      { id: 3, name: this.$t("string.year", { year: 3 }) },
      { id: 4, name: this.$t("string.year", { year: 4 }) },
      { id: 5, name: this.$t("string.year", { year: 5 }) },
      { id: 6, name: this.$t("string.year", { year: 6 }) },
    ];
    this.api.params = {
      countryCode: this.auth.Account.countryCode,
    };
    (async () => {
      await delay(200);
      this.$api.fetch(this.api);
    })();
  },
  methods: {
    changeTab(nav) {
      this.selected = nav;
      if (this.status(nav.timestampStart, nav.timestampEnd) == "ONGOING") {
        this.tickets(nav.code);
      }
    },
    async getGameImage() {
      for (let i = 0; i < this.events.length; i++) {
        await this.gameApiCall(this.events[i]);
      }
    },
    async gameApiCall(element) {
      this.gameApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/minigame/" +
        element.gameCode;

      this.gameApi.callbackReset = () => {
        this.gameApi.isLoading = true;
        this.gameApi.isError = false;
      };

      this.gameApi.callbackError = (e) => {
        this.gameApi.isLoading = false;
        this.gameApi.isError = true;
        this.gameApi.error = e;
      };
      this.gameApi.callbackSuccess = (resp) => {
        this.gameApi.isLoading = false;
        element["game"] = resp;
        if (this.selected.id == element.id) {
          this.selected = element;
          this.selectedImg = resp.imageUrl;
        }
      };
      this.gameApi.method = "GET";
      await this.$api.fetch(this.gameApi);
    },
    tickets(code) {
      this.PlayerApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/player/load";

      this.PlayerApi.callbackReset = () => {
        this.PlayerApi.isLoading = true;
        this.PlayerApi.isError = false;
      };

      this.PlayerApi.callbackError = (e) => {
        this.PlayerApi.isLoading = false;
        this.PlayerApi.isError = true;
        this.PlayerApi.error = e;
      };
      this.PlayerApi.callbackSuccess = (resp) => {
        this.PlayerApi.isLoading = false;
        if (JSON.parse(resp.Save.data).isInitToken) {
          this.ticketCount = JSON.parse(resp.Save.data).token;
        } else {
          this.ticketCount = 20;
        }
      };
      this.PlayerApi.params = {
        eventCode: code,
      };
      this.$api.fetch(this.PlayerApi);
    },
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    selectYear() {
      let tempAuth = this.$_.cloneDeep(this.auth);
      tempAuth.User.schoolYear = this.selectedYear;
      this.$store.commit("updateAuth", tempAuth);
      this.selectYearScreen = false;
      this.getSponsors(this.nextCode, this.nextRoute);
    },
    getSponsors(code, page) {
      this.$store.commit("updateSeries", this.selected);
      this.sponsorScreen = true;
      if (this.auth.Group.type == "institution") {
        this.SeriesSponsorApi.url =
          this.$api.servers.event +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/series/sponsor";

        this.SeriesSponsorApi.callbackReset = () => {
          this.SeriesSponsorApi.isLoading = true;
          this.SeriesSponsorApi.isError = false;
        };

        this.SeriesSponsorApi.callbackError = (e) => {
          this.SeriesSponsorApi.isLoading = false;
          this.SeriesSponsorApi.isError = true;
          this.SeriesSponsorApi.error = e;
        };
        this.SeriesSponsorApi.callbackSuccess = (resp) => {
          this.seriesSponsors = resp;
        };

        this.SeriesSponsorApi.params = {
          institutionId: this.auth.Institution.id,
          seriesId: this.selected.seriesId,
        };
        this.$api.fetch(this.SeriesSponsorApi);
      }
      this.EventSponsorApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/sponsor";

      this.EventSponsorApi.callbackReset = () => {
        this.EventSponsorApi.isLoading = true;
        this.EventSponsorApi.isError = false;
      };

      this.EventSponsorApi.callbackError = (e) => {
        this.EventSponsorApi.isLoading = false;
        this.EventSponsorApi.isError = true;
        this.EventSponsorApi.error = e;
      };
      this.EventSponsorApi.callbackSuccess = (resp) => {
        this.eventSponsors = resp;
        setTimeout(() => {
          this.$router.push({
            name: page,
            params: { code: code },
          });
        }, 3000);
      };

      this.EventSponsorApi.params = {
        eventCode: code,
      };
      this.$api.fetch(this.EventSponsorApi);
    },
    goToLevels(code) {
      if (this.tutorial.Ongoing == "tutExploreEvents") {
        this.$intro().exit();
        this.updateTutorial();
      }

      if (this.auth.Player.userType == "adult") {
        this.selectYearScreen = true;
        this.nextRoute = "PageEventLevels";
        this.nextCode = code;
      } else {
        this.getSponsors(code, "PageEventLevels");
      }

      // this.$router.push({
      //   name: "PageEventLevels",
      //   params: { code: code },
      // });
    },
    goToInfo(code) {
      if (this.auth.Player.userType == "adult") {
        this.selectYearScreen = true;
        this.nextRoute = "PageEventInfo";
        this.nextCode = code;
      } else {
        this.getSponsors(code, "PageEventInfo");
      }

      // this.$router.push({
      //   name: "PageEventInfo",
      //   params: { code: code },
      // });
    },
    goToRank(code) {
      if (this.auth.Player.userType == "adult") {
        this.selectYearScreen = true;
        this.nextRoute = "PageEventRank";
        this.nextCode = code;
      } else {
        this.getSponsors(code, "PageEventRank");
      }
      // this.$router.push({
      //   name: "PageEventRank",
      //   params: { code: code },
      // });
    },
    updateTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/complete";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        this.$store.commit("updateTutorial", resp);
      };
      if (this.tutorial.Ongoing == "tutExploreEvents") {
        this.tutorialApi.params = {
          key: "tutExploreEvents",
        };
      }
      this.$api.fetch(this.tutorialApi);
    },
    status(timestampStart, timestampEnd) {
      if (this.$moment().isAfter(timestampEnd)) {
        return "PAST";
      } else if (this.$moment().isAfter(timestampStart)) {
        return "ONGOING";
      } else if (this.$moment(timestampStart).diff(this.$moment(), "days") > 14) {
        return "LONGUPCOMING";
      } else {
        return "UPCOMING";
      }
    },
  },
};
</script>
  
  <style scoped>
.custom-select {
  border: 2px solid #b99c7a;
  border-radius: 0;
  background-color: #fff2e2;
}
.nav {
  display: flex;
  align-items: center;
  width: 22%;
  padding: 0 5px;
  font-size: medium;
  cursor: pointer;
}

.selected-nav {
  font-size: x-large;
  display: flex;
  align-items: center;
  width: 34%;
  padding: 0 5px;
  cursor: pointer;
}

.nav-panel {
  display: flex;
  justify-content: space-around;
  padding: 0px 5px 10px 5px;
  margin-top: -20px;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 70px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /*background-color: #f5dfc5;*/
  background-color: rgba(150, 75, 0, 0.3);
}

.text-stroke-lg {
  color: #fff;
  text-shadow: #8b5629 8px 0px 0px, #8b5629 7.93758px 0.997398px 0px,
    #8b5629 7.7513px 1.97923px 0px, #8b5629 7.44406px 2.93018px 0px,
    #8b5629 7.02066px 3.8354px 0px, #8b5629 6.48771px 4.68078px 0px,
    #8b5629 5.85351px 5.45311px 0px, #8b5629 5.12797px 6.14035px 0px,
    #8b5629 4.32242px 6.73177px 0px, #8b5629 3.44941px 7.21814px 0px,
    #8b5629 2.52258px 7.59188px 0px, #8b5629 1.55638px 7.84714px 0px,
    #8b5629 0.565898px 7.97996px 0px, #8b5629 -0.433417px 7.98825px 0px,
    #8b5629 -1.42597px 7.87189px 0px, #8b5629 -2.39627px 7.63269px 0px,
    #8b5629 -3.32917px 7.27438px 0px, #8b5629 -4.21013px 6.80256px 0px,
    #8b5629 -5.02539px 6.22459px 0px, #8b5629 -5.76223px 5.54948px 0px,
    #8b5629 -6.40915px 4.78778px 0px, #8b5629 -6.95606px 3.95136px 0px,
    #8b5629 -7.39442px 3.05329px 0px, #8b5629 -7.71739px 2.10757px 0px,
    #8b5629 -7.91994px 1.12896px 0px, #8b5629 -7.9989px 0.132735px 0px,
    #8b5629 -7.95304px -0.865561px 0px, #8b5629 -7.78307px -1.85035px 0px,
    #8b5629 -7.49165px -2.80627px 0px, #8b5629 -7.08333px -3.71839px 0px,
    #8b5629 -6.56447px -4.57249px 0px, #8b5629 -5.94318px -5.35524px 0px,
    #8b5629 -5.22915px -6.05442px 0px, #8b5629 -4.43352px -6.65912px 0px,
    #8b5629 -3.5687px -7.15991px 0px, #8b5629 -2.6482px -7.54898px 0px,
    #8b5629 -1.68637px -7.82024px 0px, #8b5629 -0.698222px -7.96947px 0px,
    #8b5629 0.300817px -7.99434px 0px, #8b5629 1.29516px -7.89446px 0px,
    #8b5629 2.2693px -7.67139px 0px, #8b5629 3.20802px -7.32862px 0px,
    #8b5629 4.09668px -6.87148px 0px, #8b5629 4.92142px -6.30711px 0px,
    #8b5629 5.66936px -5.64432px 0px, #8b5629 6.32883px -4.89346px 0px,
    #8b5629 6.88954px -4.06623px 0px, #8b5629 7.34274px -3.17555px 0px,
    #8b5629 7.68136px -2.23532px 0px, #8b5629 7.90012px -1.26021px 0px,
    #8b5629 7.9956px -0.265434px 0px;
}
.text-stroke-md {
  color: #fff;
  text-shadow: #8b5629 5px 0px 0px, #8b5629 4.90033px 0.993347px 0px,
    #8b5629 4.60531px 1.94709px 0px, #8b5629 4.12668px 2.82321px 0px,
    #8b5629 3.48353px 3.58678px 0px, #8b5629 2.70151px 4.20735px 0px,
    #8b5629 1.81179px 4.6602px 0px, #8b5629 0.849836px 4.92725px 0px,
    #8b5629 -0.145998px 4.99787px 0px, #8b5629 -1.13601px 4.86924px 0px,
    #8b5629 -2.08073px 4.54649px 0px, #8b5629 -2.94251px 4.04248px 0px,
    #8b5629 -3.68697px 3.37732px 0px, #8b5629 -4.28444px 2.57751px 0px,
    #8b5629 -4.71111px 1.67494px 0px, #8b5629 -4.94996px 0.7056px 0px,
    #8b5629 -4.99147px -0.291871px 0px, #8b5629 -4.83399px -1.27771px 0px,
    #8b5629 -4.48379px -2.2126px 0px, #8b5629 -3.95484px -3.05929px 0px,
    #8b5629 -3.26822px -3.78401px 0px, #8b5629 -2.4513px -4.35788px 0px,
    #8b5629 -1.53666px -4.75801px 0px, #8b5629 -0.560763px -4.96846px 0px,
    #8b5629 0.437495px -4.98082px 0px, #8b5629 1.41831px -4.79462px 0px,
    #8b5629 2.34258px -4.41727px 0px, #8b5629 3.17346px -3.86382px 0px,
    #8b5629 3.87783px -3.15633px 0px, #8b5629 4.4276px -2.32301px 0px,
    #8b5629 4.80085px -1.39708px 0px, #8b5629 4.98271px -0.415447px 0px;
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.menu-button {
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.game-card {
  border-radius: 5px;
  border: #8b5629 5px solid;
  margin: 0px 10px 10px 10px;
  background-color: #fecb8a;
  text-align: center;
}

.game-title {
  color: #8b5629;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.container-16x9 {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.container-1x1 {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.aspect-ratio-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2; /* Placeholder color */
  animation: skeleton-loader 1s ease-in-out infinite alternate;
}

@keyframes skeleton-loader {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}
</style>